import React from "react"
import { Box, Text } from "@chakra-ui/core"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "containers/layout"
import SEO from "components/seo"
import Masonry from "containers/masonry"
import Article from "containers/article"
import { onlyClient } from "./index"

export const KontaktTempl = ({ location, data, en: EN }) => {
  const { t } = useTranslation()

  return (
    <Layout path={location.pathname}>
      {data.strapi?.kontakt?.meta && (
        <SEO
          title={
            data.strapi?.kontakt[EN ? "meta_en" : "meta"]?.title ?? t("Kontakt")
          }
          description={
            data.strapi?.kontakt[EN ? "meta_en" : "meta"]?.description
          }
        />
      )}
      {EN && "english"}
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        w="100%"
        pos="relative"
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
      >
        <Text
          color="red"
          fontSize={["6xl", "5xl", "4xl"]}
          pb={["0.5em", "1em", "1em"]}
          lineHeight="1.25em"
        >
          {t("Kontakt und Öffnungszeiten")}
        </Text>
        {onlyClient && (
          <Masonry
            breakpointColumns={{ 2: "(min-width: 48em)" }}
            gap={["5em", "5em", "4em"]}
            defaultColumn={1}
          >
            {data.strapi?.kontakt &&
              data.strapi.kontakt[EN ? "inhalt_en" : "inhalt"] &&
              data.strapi.kontakt[EN ? "inhalt_en" : "inhalt"].map(
                (item, index) => {
                  const listItem = item.article ? (
                    <Article
                      key={index}
                      title={item.article.title}
                      subtitle={item.article.subtitle}
                      text={item.article.text}
                      useAlternativeFont={item.article.use_alternative_font}
                      showLightboxHint = "true"
                      image={item.article.cover && item.article.cover.imageFile}
                      imageGray={
                        item.article.cover && item.article.cover.imageFileGray
                      }
                      gallery={item.article.gallery && item.article.gallery}
                      justInformation={item.article.gallery.length === 0}
                      alt={item.article.cover.alternativeText}
                      copyright={item.article.cover.caption}
                    />
                  ) : (
                    (item.title || item.text || item.image) && (
                      <Article
                        key={index}
                        title={item.title}
                        text={item.text}
                        textColor="green"
                        useAlternativeFont={item.use_alternative_font}
                        showLightboxHint = "true"
                        image={item.image && item.image.imageFile}
                        justInformation={
                          item.__typename &&
                          (item.__typename.includes("Information") ||
                            item.__typename.toLowerCase().includes("text"))
                        }
                        alt={item.image && item.image.alternativeText}
                        copyright={item.image && item.image.caption}
                      />
                    )
                  )

                  return listItem
                }
              )}
          </Masonry>
        )}
      </Box>
    </Layout>
  )
}

export default KontaktTempl
